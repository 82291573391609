"use strict";

function setupVideo() {
  var videoContainer = document.querySelector('.reveal-video');
  var youtubeFrame = document.querySelector('iframe.youtubeOverlay');
  var video = videoContainer.querySelector('video');
  var altImage = videoContainer.querySelector('.altImage');
  var videoOverlay = document.querySelector('.videoOverlay');
  var videoPlayButton = document.querySelector('.video-play-button');
  var destVideo = videoOverlay.querySelector('video');
  var itemCounter = 1;
  setupVideoSlides(); // Initial setup of video properties

  changeSlideTo(1);
  videoContainer.addEventListener('mouseover', function () {
    videoContainer.querySelector('.container-video').classList.add('show');

    if (!video.classList.contains('hidden')) {
      video.play();
    }
  });
  videoContainer.addEventListener('mouseout', function () {
    if (!video.classList.contains('hidden')) {
      video.pause();
    }
  });
  videoPlayButton.addEventListener('click', function () {
    playLightboxVideo();
  });
  videoOverlay.addEventListener('click', function (event) {
    event.stopPropagation();

    if (this === videoOverlay) {
      closeLighboxVideo();
    }
  });
  var closeButton = videoOverlay.querySelector('.closeButton');
  closeButton.addEventListener('click', function (e) {
    closeLighboxVideo();
  });
  videoContainer.querySelector('a.next').addEventListener('click', function () {
    nextSlide();
  });
  videoContainer.querySelector('a.prev').addEventListener('click', function () {
    prevSlide();
  });
  setupVideoControls();
  var youtubeContainer = document.querySelector('.videoLightbox div.iframeContainer');
  var youtubeLightboxFrame = youtubeContainer.querySelector('iframe.youtubeOverlay');

  function playLightboxVideo() {
    var destSrc = destVideo.getAttribute('src');
    var originSrc = video.getAttribute('src');
    var alreadyPlaying = destSrc === originSrc;
    var youtubeID = document.querySelector('iframe.youtubeOverlay').getAttribute('data-youtube');
    if (!alreadyPlaying) destVideo.setAttribute('src', originSrc);

    if (youtubeID != '') {
      destVideo.classList.add('hidden');
      destVideo.pause();
      youtubeContainer.classList.remove('hidden');
      youtubeLightboxFrame.setAttribute('src', "https://www.youtube.com/embed/".concat(youtubeID, "?autoplay=1&cc_load_policy=1&rel=0&controls=1&showinfo=0&mute=0"));
    } else {
      destVideo.classList.remove('hidden');
      destVideo.play();
      youtubeContainer.classList.add('hidden');
      youtubeLightboxFrame.setAttribute('src', "");
    }

    videoOverlay.classList.add('active');
  }

  function closeLighboxVideo() {
    videoOverlay.classList.remove('active');
    destVideo.pause();
    youtubeLightboxFrame.setAttribute('src', "");
  }

  function setupVideoSlides() {
    var videoItems = document.querySelectorAll('.hidden-data.items .item');
    videoItems.forEach(function (item) {
      var thisContent = item.querySelector('.item-content');
      var thisVideo = item.querySelector('.item-video');
      item.setAttribute('data-item', itemCounter);
      item.setAttribute('id', "data-item-".concat(itemCounter));
      thisContent.setAttribute('data-item', itemCounter);
      thisVideo.setAttribute('data-item', itemCounter);
      itemCounter++;
    });
  }

  function changeSlideTo(dataNum) {
    var newDataset = document.getElementById("data-item-".concat(dataNum));
    var newVidSrc = newDataset.querySelector('video').getAttribute('src');
    var newVidPoster = newDataset.querySelector('video').getAttribute('poster');
    var contentContainer = document.querySelector('.container-video-slideshow .content-container');
    var newHTML = newDataset.querySelector('.item-content').innerHTML;
    var videoSlideshow = document.querySelector('.container-video-slideshow');
    videoSlideshow.classList.add('transition');
    var youtubeID = newDataset.querySelector('video').getAttribute('data-youtube');
    setTimeout(function () {
      if (youtubeID !== '') {
        video.classList.add('hidden');
        altImage.classList.remove('hidden');
        altImage.style.backgroundImage = "url(".concat(newVidPoster, ")");
        videoContainer.querySelector('.content-right').classList.add('hidden');
        youtubeFrame.classList.remove('hidden');
        youtubeFrame.setAttribute('src', "https://www.youtube.com/embed/".concat(youtubeID, "?autoplay=1&playlist=").concat(youtubeID, "&loop=1&cc_load_policy=1&rel=0&controls=0&showinfo=0&mute=1"));
        youtubeFrame.setAttribute('data-youtube', youtubeID);
        videoContainer.querySelector('.content-right').classList.remove('hidden');
      } else if (newVidSrc) {
        video.setAttribute('src', newVidSrc);
        video.setAttribute('poster', newVidPoster);
        video.classList.remove('hidden'); // altImage.classList.add('hidden');

        altImage.style.backgroundImage = "url(".concat(newVidPoster, ")");
        videoContainer.querySelector('.content-right').classList.remove('hidden');
        youtubeFrame.classList.add('hidden');
        youtubeFrame.setAttribute('src', '');
        youtubeFrame.setAttribute('data-youtube', '');
      } else {
        video.classList.add('hidden');
        altImage.classList.remove('hidden');
        altImage.style.backgroundImage = "url(".concat(newVidPoster, ")");
        videoContainer.querySelector('.content-right').classList.add('hidden');
        youtubeFrame.setAttribute('src', '');
        youtubeFrame.setAttribute('data-youtube', '');
      }

      contentContainer.innerHTML = newHTML;
      contentContainer.setAttribute('data-current-item', dataNum);
      videoSlideshow.classList.remove('transition');
    }, 750);
  }

  function nextSlide() {
    var currentSlide = document.querySelector('.container-video-slideshow .content-container');
    var currentSlideNum = parseInt(currentSlide.getAttribute('data-current-item'));
    var nextSlideNum = currentSlideNum + 1;
    if (nextSlideNum >= itemCounter) nextSlideNum = 1;
    changeSlideTo(nextSlideNum);
  }

  function prevSlide() {
    var currentSlide = document.querySelector('.container-video-slideshow .content-container');
    var currentSlideNum = parseInt(currentSlide.getAttribute('data-current-item'));
    var prevSlideNum = currentSlideNum - 1;
    if (prevSlideNum <= 0) prevSlideNum = itemCounter - 1;
    changeSlideTo(prevSlideNum);
  }
} // end videoHoverEvent()