"use strict";

function setupFaqSection() {
  var faqItems = document.querySelectorAll('.faq-questions .question');
  setupContentHeight(faqItems); // helper functions

  function setupContentHeight(faqItems) {
    // Setup the initial height of the FAQ Items, and store
    // that height in a attribute.  We'll use this later
    // to make sure we have a smooth effect when transitioning.
    // 
    // This is similar to how jQuery's slideUp / slideDown 
    // functions work.
    var counter = 0;
    faqItems.forEach(function (item) {
      var content = item.querySelector('.content');
      var titleRow = item.querySelector('.titleRow'); // expand all (just in case) to get initial height, 
      // then collapse to make it look like nothing happened

      item.classList.remove('collapsed');
      content.setAttribute('data-height', content.offsetHeight); // give the browser enough time to grab the proper height

      setTimeout(function () {
        if (counter) {
          // expand the very first question
          collapseContent(item);
        } else {
          // collapse the rest
          expandContent(item);
        }

        ;
        counter++;
      }, 1); // delayed to prevent flash on load ⚡️

      setTimeout(function () {
        content.classList.add('has-transition');
      }, 25);
      titleRow.addEventListener('click', function (e) {
        e.preventDefault();
        toggleSection(item);
      });
    });
  }

  function toggleSection(question) {
    var isCollapsed = question.classList.contains('collapsed'); // question.classList.toggle('collapsed');

    if (isCollapsed) {
      expandContent(question);
    } else {
      collapseContent(question);
    }
  }

  function expandContent(question) {
    faqItems.forEach(function (item) {
      collapseContent(item);
    });
    question.classList.remove('collapsed');
    var content = question.querySelector('.content');
    content.style.maxHeight = content.getAttribute('data-height') + 'px';
  }

  function collapseContent(question) {
    var content = question.querySelector('.content');
    content.parentNode.classList.add('collapsed');
    content.style.maxHeight = '0px';
  }
}