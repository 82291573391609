"use strict";

function setupPurpleBanner() {
  try {
    var showPurpleBanner = function showPurpleBanner() {
      purpleBanner.classList.remove('hidden');
      setTimeout(function () {
        showPurpleBannerSections();
      }, 550);
    };

    var showPurpleBannerSections = function showPurpleBannerSections() {
      var animationTimer = 0;
      bannerSliders.forEach(function (slider) {
        setTimeout(function () {
          slider.classList.remove('slideFromBottom');
        }, animationTimer);
        animationTimer += 400;
      });
    };

    var cloneHeaderForMobile = function cloneHeaderForMobile(modularHeader, mobileHeader) {
      var boxTitle = modularHeader.querySelector('.boxTitle').textContent;
      var headerTitle = modularHeader.querySelector('h1').textContent;
      var text = modularHeader.querySelector('p').textContent;
      var btnText = modularHeader.querySelector('.linkText').textContent;
      var btnLink = modularHeader.querySelector('.btn').getAttribute('href');
      var firstImage = modularHeader.querySelector('.reveal-img');
      var firstImageBg = window.getComputedStyle(firstImage).backgroundImage;
      var newBoxTitle = mobileHeader.querySelector('.boxTitle');
      var newHeaderTitle = mobileHeader.querySelector('h1');
      var newText = mobileHeader.querySelector('p');
      var newBtnText = mobileHeader.querySelector('.linkText');
      var newBtn = mobileHeader.querySelector('.btn');
      var newImage = mobileHeader.querySelector('.reveal-img');
      newBoxTitle.textContent = boxTitle;
      newHeaderTitle.textContent = headerTitle;
      newText.textContent = text;
      newBtnText.textContent = btnText;
      newBtn.setAttribute('href', btnLink);
      newImage.style.backgroundImage = firstImageBg;
    };

    var getLargest = function getLargest(elements) {
      var startHeight = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      var thisHeight = parseInt(startHeight);
      elements.forEach(function (el) {
        if (el.offsetHeight > thisHeight) thisHeight = el.offsetHeight;
        return thisHeight;
      });
      return parseInt(thisHeight);
    };

    var getWidth = function getWidth() {
      if (self.innerWidth) {
        return self.innerWidth;
      }

      if (document.documentElement && document.documentElement.clientWidth) {
        return document.documentElement.clientWidth;
      }

      if (document.body) {
        return document.body.clientWidth;
      }
    };

    var purpleBanner = document.querySelector('.purple-header-banner');
    var header = document.querySelector('.modular-desktop');
    var mobileHeader = document.querySelector('.modular-mobile');
    var bannerSliders = purpleBanner.querySelectorAll('.slideFromBottom');
    var textArea = document.querySelector('.slideshow .textArea');
    var textContent = textArea.querySelectorAll('.content');
    var mobileHeight = getLargest(textContent) + 500;
    var newMinHeight = getLargest(textContent, 600) + 'px';

    if (getWidth() < 568) {
      newMinHeight = getLargest(textContent, mobileHeight) + 'px';
    }

    textArea.style.minHeight = newMinHeight;
    textArea.parentElement.style.minHeight = newMinHeight;
    showPurpleBanner();
    cloneHeaderForMobile(header, mobileHeader);
  } catch (error) {
    console.error(error);
  }
}