"use strict";

// Block Animation Modules
function animateModule(thisModule) {
  // If we're passed a nodelist, iterate over the function.
  // Used for pages where we have to execute different html
  // for mobile & desktop, but want to run both animations
  // at the same time.
  if (NodeList.prototype.isPrototypeOf(thisModule)) {
    thisModule.forEach(function (singleModule) {
      animateModule(singleModule);
    });
    return false;
  } // Conditionals


  if (!thisModule) {
    return false;
  }

  if (thisModule.classList.contains('animated')) {
    return false;
  }

  if (thisModule.classList.contains('has-timer')) {
    var startDelay = parseInt(thisModule.getAttribute('data-startDelay'));
    thisModule.classList.remove('has-timer');
    setTimeout(function () {
      animateModule(thisModule); // run again after timeout. #recursion
    }, startDelay);
    return false;
  } // Main Function


  var animationTimer = 400;
  var revealRow = thisModule.querySelector('.row-reveal');

  if (revealRow) {
    setTimeout(function () {
      setRowHeight(revealRow);
      revealKickoff(thisModule, animationTimer);
    }, 10); // fallback for IE

    setTimeout(function () {
      setRowHeight(revealRow);
    }, 1000);
  } // Helper Functions


  function giveBacking(element) {
    var elementNum = parseInt(element.getAttribute('data-order'));

    if (element.classList.contains('reveal-has-back')) {
      var backClass = ".reveal-".concat(elementNum, "-backing");
      thisModule.querySelector(backClass).classList.remove('reveal-start');
    }

    if (element.classList.contains('reveal-has-plus')) {
      var plusIcon = thisModule.querySelector(".plus-icon");
      if (plusIcon) plusIcon.classList.remove('reveal-start');
    }
  }

  function setRowHeight(row) {
    var revealItems = thisModule.querySelectorAll('.reveal');
    var revealRowHeight = 0;
    revealItems.forEach(function (element) {
      var elementStyle = window.getComputedStyle(element);
      var elementHeight = parseInt(element.offsetHeight) + parseInt(elementStyle.top);
      if (elementHeight > revealRowHeight) revealRowHeight = elementHeight;
    });
    row.style.minHeight = "".concat(revealRowHeight, "px");
  }

  ;

  function revealKickoff(thisModule, timeout) {
    var revealItems = thisModule.querySelectorAll('.reveal');
    var thisTimeout = timeout;
    var secondTimeout = timeout * revealItems.length;
    revealItems.forEach(function (item) {
      setTimeout(function () {
        item.classList.remove('reveal-start');
        giveBacking(item);
        setTimeout(function () {
          item.querySelector('.overlay-solid').style.transform = "translate3d(100%, 0px, 0px)"; // extended line for button

          if (item.querySelector('.btn-clear')) thisModule.querySelector('.newLine').classList.remove('inactive');
        }, secondTimeout);
      }, thisTimeout);
      thisTimeout = thisTimeout + timeout;
    });
  } // Let's mark this module as animated


  thisModule.classList.add('animated');
}

function setupStoryButtonOverflow() {
  // This function will setup the extra line in the .btn-clear cta
  // on the modular reveal elements (mostly used in 'featured story')
  // Call once per page;
  try {
    var storyButtons = document.querySelectorAll('.reveal .btn-clear');
    storyButtons.forEach(function (storyButton) {
      var thisContainer = storyButton.closest('.row-reveal'); // setup the new line decoration

      var newLine = document.createElement('div'); // top position

      newLine.style.top = "calc(".concat(getOffset(storyButton).top - getOffset(thisContainer).top, "px + 34px)"); // left position

      newLine.style.left = "calc(".concat(getOffset(storyButton).left - getOffset(thisContainer).left, "px - 125px)"); // add class for css

      newLine.classList.add('newLine', 'inactive'); // throw it inside the same container

      thisContainer.appendChild(newLine);
    });
  } catch (error) {
    console.log('No story elements with overflow button found.');
  }
}