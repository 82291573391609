"use strict";

// controller for slideshow
// set maxWidth of greenLine based on linkText
function initSlideshow(slideshow) {
  var slideTabs = document.querySelector('.section-slideshow .slideTabs');
  var slideshowLinks = document.querySelectorAll('.section-slideshow .slideTabs .tab');
  var slideshowPics = slideshow.querySelectorAll('.picArea');
  var slideContent = slideshow.querySelectorAll('.content');
  var hasDropdowns = document.querySelector('.slideTabs.has-dropdowns');
  var mobileTab = document.querySelector('.section-slideshow .mobile-tab');
  var maxSlides = 0;

  if (slideshowLinks.length > 0) {
    maxSlides = parseInt(slideshowLinks.length);
  } else {
    maxSlides = parseInt(slideContent.length);
  }

  ; // set minHeight for slideshow area

  setSlideshowMinHeight(); // Setup logic for dropdowns if they exist

  if (hasDropdowns) {
    setupDropdownTabs(slideshowLinks);
  }

  ; // Setup mobile tab behavior

  if (mobileTab) setupMobileTab(mobileTab); // setup green bar for hover effect w/ some JS magic
  // Allows us to reuse CSS but with the green bar hidden
  // by default per design specs.

  setTimeout(function () {
    // wait for everything to settle
    slideshowLinks.forEach(function (link) {
      var greenLine = link.querySelector('.greenLine');
      var linkText = link.querySelector('.linkText');

      if (greenLine) {
        // only do the following if greenline exists
        var linkStyles = window.getComputedStyle(linkText);
        var greenLineStyles = window.getComputedStyle(greenLine);
        linkText.style.display = 'inline-block';
        greenLine.style.width = parseInt(linkStyles.width) - parseInt(linkStyles.paddingLeft) * 2 + 'px';
        greenLine.style.minHeight = "calc(".concat(parseInt(linkStyles.height), "px + 1em)");

        if (parseInt(linkStyles.height) > 47) {
          greenLine.style.minHeight = '68px';
        }

        if (parseInt(linkStyles.height) > 16 && parseInt(linkStyles.height) < 48) {
          greenLine.style.top = '11px';
        } else if (parseInt(linkStyles.height) >= 47) {
          greenLine.style.top = '2px';
          setTimeout(function () {
            if (getSize('w') < 1199) {
              greenLine.style.top = '16px';
            }
          }, 1500);
        }
      } // add click function


      link.addEventListener('click', function (event) {
        // event.preventDefault();
        changeSlide(link, maxSlides);
      });
    });
  }, 10); // Click events for previous and next buttons

  var btnPrev = slideshow.querySelector('.button.prev');
  var btnNext = slideshow.querySelector('.button.next');
  btnPrev.addEventListener('click', function () {
    if (parseInt(this.getAttribute('data-slide')) === 0) {
      this.setAttribute('data-slide', maxSlides);
    }

    changeSlide(this, maxSlides);
  });
  btnNext.addEventListener('click', function () {
    changeSlide(this, maxSlides);
  }); // Sub Functions for Slideshow Only

  function changeSlide(triggerElement, maxSlides) {
    // Changes the slide based on the clicked element's data-slide attribute
    var newSlideNumber = triggerElement.getAttribute('data-slide');
    var elementsToUpdate = [slideshowLinks, slideContent, slideshowPics];
    slideshowUpdateElements(elementsToUpdate, newSlideNumber);
    updateSlideshowButtons(newSlideNumber, maxSlides);
    if (mobileTab) updateMobileTab(mobileTab, newSlideNumber);
  }

  function slideshowUpdateElements(nodeList, newSlideNumber) {
    // 'nodeList' can be of type nodeList, or an array of nodeLists
    // thanks to recursive magic
    if (Array.isArray(nodeList)) {
      nodeList.forEach(function (e) {
        slideshowUpdateElements(e, newSlideNumber);
      });
    } else {
      nodeList.forEach(function (element) {
        if (element.getAttribute('data-slide') === newSlideNumber) {
          if (element.classList.contains('is-dropdown')) {
            triggerSlideshow(element, nodeList);
          } else {
            element.classList.add('active');
            element.classList.remove('inactive');
          }
        } else {
          if (element.classList.contains('is-dropdown')) {} else {
            element.classList.remove('active');
            element.classList.add('inactive');
          }
        }

        ;
      });
    }

    ;
  }

  ; // Update the slideshow previous and next buttons

  function updateSlideshowButtons(newSlideNum, maxSlides) {
    var btnPrev = slideshow.querySelector('.button.prev');
    var btnNext = slideshow.querySelector('.button.next');
    var prevSlideNum = parseInt(newSlideNum) - 1;
    var nextSlideNum = parseInt(newSlideNum) + 1;
    if (prevSlideNum <= 0) prevSlideNum = maxSlides;
    if (nextSlideNum > parseInt(maxSlides)) nextSlideNum = 1;
    btnPrev.setAttribute('data-slide', prevSlideNum);
    btnNext.setAttribute('data-slide', nextSlideNum);
  } // Close dropdown tabs if they're dropdown


  function closeDropdownTabs(tabs) {
    tabs.forEach(function (tab) {
      var tabContent = tab.querySelector('.content');
      tab.classList.remove('active');
      tabContent.style.maxHeight = '0px';
    });
  } // setup styles and functionality of dropdown tabs


  function setupDropdownTabs(tabs) {
    // let tabs = document.querySelectorAll('.section-slideshow .tab');
    var slideCounter = 1;
    tabs.forEach(function (tab) {
      tab.setAttribute('data-slide', slideCounter++);
      var tabContent = tab.querySelector('.content');
      var tabContentHeight = tabContent.offsetHeight;
      tabContent.setAttribute('data-height', tabContentHeight + 'px');
      tab.classList.remove('active');
    }); // Activate the first tab so something is expanded

    tabs[0].classList.add('active');
  }

  function triggerSlideshow(thisTab, allTabs) {
    var isActive = thisTab.classList.contains('active');
    var tabContent = thisTab.querySelector('.content');
    closeDropdownTabs(allTabs);

    if (isActive) {
      tabContent.style.maxHeight = '0px';
      thisTab.classList.remove('active');
      thisTab.classList.remove('activeToggle');
    } else {
      var thisHeight = tabContent.getAttribute('data-height');
      thisTab.classList.add('active');
      tabContent.style.maxHeight = thisHeight;
      thisTab.classList.add('activeToggle');
    }
  }

  function setSlideshowMinHeight() {
    var newHeight = Math.round(document.querySelector('.picArea:not(.inactive)').getBoundingClientRect().height);
    var slideshowArea = document.querySelector('.slideshow');

    if (slideshowArea.classList.contains('height-plus-100')) {
      slideshowArea.style.minHeight = "".concat(newHeight + 100, "px");
    } else if (!slideshowArea.classList.contains('has-height')) {
      slideshowArea.style.minHeight = "".concat(newHeight + 50, "px");
    }
  }

  function updateMobileTab(mobileTab, newSlideNumber) {
    if (!mobileTab) return false;
    slideTabs.classList.remove('active');
    mobileTab.classList.remove('active');
    var newText = document.querySelector(".tab[data-slide=\"".concat(newSlideNumber, "\"] .linkText"));
    mobileTab.querySelector('.linkText').innerHTML = newText.innerHTML;
  }

  function setupMobileTab(mobileTab) {
    updateMobileTab(mobileTab, 1); // initial

    mobileTab.addEventListener('click', function (event) {
      event.preventDefault();
      slideTabs.classList.toggle('active');
      mobileTab.classList.toggle('active');
    });
  }
}