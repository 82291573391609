"use strict";

// Polyfill for IE forEach() on NodeLists 
if ('NodeList' in window && !NodeList.prototype.forEach) {
  console.info('polyfill for IE11');

  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;

    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
} // Custom Event Ployfill (IE 9-11)
// Source: https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent#Polyfill


(function () {
  var throttle = function throttle(type, name, obj) {
    obj = obj || window;
    var running = false;

    var func = function func() {
      if (running) {
        return;
      }

      running = true;
      requestAnimationFrame(function () {
        obj.dispatchEvent(new CustomEvent(name));
        running = false;
      });
    };

    obj.addEventListener(type, func);
  };
  /* init - you can init any event */


  throttle("resize", "optimizedResize");
})(); // Custom event for throttling resource intensive functions.
// Source: https://developer.mozilla.org/en-US/docs/Web/Events/resize
// // handle event
// window.addEventListener("optimizedResize", function () {
// 	console.log("Resource conscious resize callback!");
// });
// IE Polyfill for element.closest();


if (!Element.prototype.matches) Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;

if (!Element.prototype.closest) {
  Element.prototype.closest = function (s) {
    var el = this;
    if (!document.documentElement.contains(el)) return null;

    do {
      if (el.matches(s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);

    return null;
  };
} // .remove() polyfill for IE
// Source: https://stackoverflow.com/questions/20428877/javascript-remove-doesnt-work-in-ie
// Create Element.remove() function if not exist


if (!('remove' in Element.prototype)) {
  Element.prototype.remove = function () {
    if (this.parentNode) {
      this.parentNode.removeChild(this);
    }
  };
} // Call remove() according to your need
// child.remove();