"use strict";

function templateStory() {
  if (jQuery('.toggleArea').length) {
    var toggleAreas = document.querySelectorAll('.toggleArea');
    var toggleAll = document.getElementById('all-stories').parentElement;
    toggleAreas.forEach(function (toggleArea) {
      var input = toggleArea.querySelector('input');
      var realSearchForm = document.querySelector('#storyfilter form.search-form');
      var searchCats = realSearchForm.querySelector('input#cat');
      var thisCat = toggleArea.getAttribute('data-cat-id');
      toggleArea.querySelector('label').addEventListener('click', function (e) {
        e.preventDefault();

        if (toggleArea === toggleAll) {
          clearCheckboxes(toggleAreas);
          toggleArea.classList.add('active');
          input.checked = true;
          searchCats.value = "";
        } else {
          toggleAll.classList.remove('active');
          toggleAll.querySelector('input').checked = false;

          if (input.checked) {
            uncheckToggle(toggleArea);
          } else {
            checkToggle(toggleArea);
          }
        }
      });
      var urlCats = getQueryVariable('cat');

      if (urlCats != false) {
        var startingCats = urlCats.split('%2C');

        if (startingCats.includes(thisCat)) {
          checkToggle(toggleArea);
        }
      }

      function uncheckToggle(toggleArea) {
        var input = toggleArea.querySelector('input');
        input.checked = false;
        toggleArea.classList.remove('active');
        searchCats.value = removeFromList(thisCat, searchCats.value);
      }

      function checkToggle(toggleArea) {
        var input = toggleArea.querySelector('input');
        input.checked = true;
        toggleArea.classList.add('active');
        searchCats.value = addToList(thisCat, searchCats.value);
      }

      function clearCheckboxes(toggleAreas) {
        toggleAreas.forEach(function (box) {
          box.classList.remove('active');
          var thisLabel = box.querySelector('label');
          var thisInput = box.querySelector('input');
          thisInput.checked = false;
        });
      }
    }); // end toggleAreas foreach
  }
} // Functions to quickly swap out string values


function removeFromList(val, list) {
  var listArray = list.split(',');
  listArray = arrayRemove(listArray, val);
  return listArray.join();
}

function addToList(val, list) {
  var listArray = list.split(',');
  listArray.push(val);
  return listArray.join();
} // help from https://love2dev.com/blog/javascript-remove-from-array/


function arrayRemove(arr, value) {
  return arr.filter(function (ele) {
    return ele != value;
  });
}