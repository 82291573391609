"use strict";

function activateHistorySlider() {
  // History Slider for about page
  // Assumes only 1 history slider per page
  var historySlider = document.querySelector('.history-slider');
  var historySlides = document.querySelectorAll('.history-slider .slide');
  var slideNum = 0;
  var slideWidth = 0;
  setupSlides(historySlides);
  var activeStartSlide = document.querySelector('.history-slider .active');
  var activePos = activeStartSlide.getAttribute('data-slideNum'); // Kickoff initial slide position

  changeSlidePos(activePos);

  function setupSlides(slides) {
    // setup for slides
    slides.forEach(function (slide) {
      slideNum++;
      var thisStyle = window.getComputedStyle(slide);
      var thisMargin = parseInt(thisStyle.marginLeft) + parseInt(thisStyle.marginRight);
      var thisTotalWidth = slide.offsetWidth + thisMargin;
      var thisPrev = slide.querySelector('.slideControls .prev');
      var thisNext = slide.querySelector('.slideControls .next');
      if (thisTotalWidth > slideWidth) slideWidth = thisTotalWidth;
      slide.setAttribute('data-slideNum', slideNum);

      if (slide.classList.contains('startActive')) {
        slide.classList.remove('startActive');
        slide.classList.add('active');
      } else {
        slide.classList.remove('active');
        slide.classList.add('inactive');
      }

      thisPrev.setAttribute('data-targetSlide', slideNum - 1);
      thisNext.setAttribute('data-targetSlide', slideNum + 1);
    });
    var slideControls = document.querySelectorAll('.history-slider .slideControls .control');
    slideControls.forEach(function (control) {
      control.addEventListener('click', function (e) {
        var thisTarget = control.getAttribute('data-targetSlide');
        if (thisTarget == 0) thisTarget = slideNum;
        if (thisTarget > slideNum) thisTarget = 1;
        activateSlide(thisTarget);
      });
    });
  }

  ;

  function activateSlide(slideNum) {
    var slide = document.querySelector(".history-slider .slide[data-slideNum=\"".concat(slideNum, "\"]"));
    var historySlides = document.querySelectorAll('.history-slider .slide');
    historySlides.forEach(function (el) {
      el.classList.add('inactive');
    });
    slide.classList.remove('inactive');
    changeSlidePos(slideNum);
  }

  function changeSlidePos(num) {
    var newOffset = (num - 1) * slideWidth;
    historySlider.style.transform = "translate3d(".concat(-newOffset, "px, 0px, 0px)");
  }
}