"use strict";

/**
 * Parallax Animation Functions
 */
function kickoffParallax() {
  /**
   * Parallax code from over the years for quick and smooth parallax.
   * Basically, we're using a variable to monitor the animation
   * frame, and only pushing our parallax updates during an animation
   * frame, and only when the previous frame has painted.
   * 
   * #performantparallax
   */
  try {
    // initial request in case the user hasen't scrolled
    var updatePage = function updatePage() {
      // updates the parallax elements on the page
      var currentPos = lastKnownPos;
      ticking = false;
      if (currentPos < 0) currentPos = 0; // no negative values

      if (flyoutItems) {
        animateFlyouts(flyoutItems, currentPos);
      }
    };

    var animateFlyouts = function animateFlyouts(elements, currentPos) {
      // Animates the parallax elements
      elements.forEach(function (element) {
        var flyoutSpeed = element.getAttribute('data-flyoutspeed'); // let parent = element.closest('div');

        var parent = element.parentElement;
        var parentCenter = parent.offsetHeight / 2;
        var parentPos = parent.getBoundingClientRect().top + parentCenter + windowCenter - windowHeight;
        var scrollDiff = parentPos * -flyoutSpeed;
        element.style.transform = "translate3D(0px, ".concat(scrollDiff, "px, 0px)");
      });
    };
    /**
     * Helper Functions
     */


    var requestAnimationTick = function requestAnimationTick() {
      if (!ticking) {
        // only update if JS isn't busy
        lastKnownPos = window.pageYOffset;
        window.requestAnimationFrame(updatePage);
      }

      ticking = true;
    };

    var flyoutItems = document.querySelectorAll('.flyoutSelf');
    document.addEventListener('scroll', function () {
      requestAnimationTick(); // runs everything when scrolled
    });
    var lastKnownPos = 0;
    var windowHeight = window.innerHeight;
    var windowCenter = windowHeight / 2;
    var ticking = false;
    requestAnimationTick();
  } catch (error) {
    console.error(error);
  }
}