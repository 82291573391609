"use strict";

// Mobile nav functionality
// Setup nav menu - clone desktop links for tablet
document.addEventListener('DOMContentLoaded', function () {
  var fixedLinksCode = document.querySelector('.navbar-fixedLinks.desktop').innerHTML;

  function cloneForTablet(callback) {
    document.querySelector('.navbar-fixedLinks.tablet').innerHTML = fixedLinksCode;
    callback();
  }

  cloneForTablet(setupSearchMirror);
  var tabletDonateBtn = document.querySelector('.navbar-fixedLinks.tablet .donate a');
  tabletDonateBtn.classList.add('hover-cta', 'btn');
  tabletDonateBtn.innerHTML = "<span class=\"linkText\">".concat(tabletDonateBtn.innerHTML, "</span><span class=\"greenLine\" aria-hidden=\"true\"></span>");
  setupNavLength();
}); // Tablet & Mobile Dropdown setup

var hasDropdowns = document.querySelectorAll('.menu-item-has-children');
hasDropdowns.forEach(function (hasDropdown) {
  hasDropdown.addEventListener('click', function (event) {
    this.classList.toggle('active');
    toggleSubNav(hasDropdown);
    hasDropdowns.forEach(function (otherDropdowns) {
      if (otherDropdowns !== hasDropdown) {
        otherDropdowns.classList.remove('active');
        var otherSubMenu = otherDropdowns.querySelector('.sub-menu');
        otherSubMenu.style.maxHeight = '';
      }
    });
  }); // Prevent default on click for tablet and mobile links w/ dropdown

  var thisLink = hasDropdown.querySelector('a');
  thisLink.addEventListener('click', function (event) {
    if (getSize('w') < 1200) {
      event.preventDefault();
      return false;
    }
  }); // Tablet & Mobile extra dropdown links

  var thisURL = thisLink.getAttribute('href');

  if (thisURL !== '' && thisURL !== '#') {
    var newLink = hasDropdown.cloneNode(true);
    var thisDropdown = hasDropdown.querySelector('.sub-menu');
    newLink.classList.remove('menu-item-has-children');
    newLink.classList.add('dropdown-clone');
    var secondDropdown = newLink.querySelector('.sub-menu');
    secondDropdown.remove();
    thisDropdown.insertBefore(newLink, thisDropdown.firstChild);
  } else {
    // Change pointer on non-links in navigation to avoid link confusion.
    thisLink.style.cursor = 'default';
    thisLink.addEventListener('click', function (e) {
      if (thisLink === this) {
        e.preventDefault();
        return false;
      }
    });
  }

  ;
}); // setup mobile nav

var mobileDropdowns = document.querySelectorAll('.navbar-container .sub-menu');
var stepTimer = 50;
mobileDropdowns.forEach(function (item) {
  item.classList.add('no-transition');
  setTimeout(function () {
    item.parentElement.classList.add('active');
    item.setAttribute('data-height', item.offsetHeight);
    item.parentElement.classList.remove('active');
    setTimeout(function () {
      item.classList.remove('no-transition');
    }, stepTimer);
  }, stepTimer);
});
var mobileNav = document.querySelector('.navbar .right');
var navToggle = document.querySelector('.mobile-navToggle');
navToggle.addEventListener('click', function (event) {
  event.stopPropagation();
  event.preventDefault();
  toggleMobileNav(mobileNav);
  this.classList.toggle('expanded');
});

function toggleSubNav(hasDropdown) {
  var subMenu = hasDropdown.querySelector('.sub-menu');

  if (hasDropdown.classList.contains('active')) {
    subMenu.style.maxHeight = subMenu.getAttribute('data-height') + 'px';
  } else {
    subMenu.style.maxHeight = '';
  }
}

;

function toggleMobileNav(nav) {
  if (nav.classList.contains('expanded')) {
    nav.classList.remove('expanded');
  } else {
    nav.classList.add('expanded');
  }
}

function setupNavLength() {
  setTimeout(function () {
    var donateButton = document.querySelector('.desktop .donate a .linkText');
    var donateStyles = window.getComputedStyle(donateButton);
    var donateRect = donateButton.getBoundingClientRect();
    var greenLine = donateButton.parentElement.querySelector('.greenLine');
    var linkOffsetLeft = donateButton.offsetLeft - donateButton.parentElement.offsetLeft;
    greenLine.style.width = "".concat(donateRect.width + 2, "px");
    greenLine.style.left = "".concat(donateButton.offsetLeft - 2, "px");
    greenLine.classList.remove('invisible');
  }, 100);
} // Bind stylized search areas in navbar to hidden wordpress search field.
// To be executed as callback once tablet search has been setup by JS


function setupSearchMirror() {
  var searchString = getQueryVariable('s');
  var searchInputs = document.querySelectorAll('input.search-box');
  var searchInputsGenerated = document.querySelectorAll('input.search-field');
  var searchHidden = document.querySelector('.search-mirror input.search-field');
  searchInputs.forEach(function (searchInput) {
    if (searchHidden) {
      searchInput.addEventListener('input', function (e) {
        searchHidden.value = searchInput.value;
        searchHidden.setAttribute('value', searchInput.value);
      });
      searchInput.addEventListener('keyup', function (e) {
        if (e.keyCode === 13) {
          document.querySelector('.search-mirror input.search-submit').click();
        }
      });
    }
  });
  searchInputsGenerated.forEach(function (genInput) {
    if (searchString != false) {
      genInput.value = searchString;
    }
  });
  var searchButtons = document.querySelectorAll('.navbar-fixedLinks .search-icon');
  searchButtons.forEach(function (searchButton) {
    searchButton.addEventListener('click', function (e) {
      if (searchHidden.value != '' && searchHidden.value != null) {
        document.querySelector('.search-mirror input.search-submit').click();
      }
    });
  });
} // Get query var - from:
// https://css-tricks.com/snippets/javascript/get-url-variables/


function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");

  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");

    if (pair[0] == variable) {
      return pair[1];
    }
  }

  return false;
}