"use strict";

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function ($, _c, undefined) {
  'use strict'; // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.

  var Sage = {
    // All pages
    'common': {
      init: function init() {
        // JavaScript to be fired on all pages
        // Smooth Scrolling
        // (function () {
        //   $('.scrollto').on('click', function (e) {
        //     e.preventDefault();
        //     var section = $(this).attr('href');
        //     $('html, body').animate({
        //       scrollTop: $(section).offset().top
        //     }, 1000);
        //   });
        // })();
        // Search Box
        $('.search-button').click(function () {
          $(this).parent().toggleClass('open'); // console.log('search-button activated!');
        }); // Header Animation

        var headerAnimation = false;
        headerAnimation = document.querySelectorAll('.modular-header');

        if (headerAnimation) {
          animateModule(headerAnimation);
        } // found in modules/_amaf-header
        // Slideshow (if page has one)
        // found in modules/_section-slideshow


        var slideshow = false;
        slideshow = document.querySelector('.section-slideshow .slideshow');

        if (slideshow) {
          initSlideshow(slideshow);
        } // Prevent '#' placeholder links from triggering page top


        var notRealLinks = document.querySelectorAll('a[href="#"]');
        notRealLinks.forEach(function (link) {
          link.addEventListener('click', function (event) {
            if (!link.classList.contains('hover-cta')) {
              event.preventDefault(); // console.log('Link Prevented!');
            }
          });
        });
        kickoffParallax(); // See ./lib/utils/_cb-parallax.js

        setupStoryButtonOverflow(); // See ./lib/modules/_amaf-header.js

        setupVideoControls();
        setupFormButtons();
        setupSearchMirror();
        setTimeout(function () {
          kickoffScrollEvents(); // ./lib/utils/_cb-scrolling-events.js
        }, 1000);
        accessibleNav();
        var registrationTextarea = document.getElementById('wppb-form-element-11');

        if (registrationTextarea !== null) {
          registrationTextarea.querySelector('textarea').setAttribute('maxlength', '250');
        }
      },
      finalize: function finalize() {// JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'page_template_template_home': {
      init: function init() {// templateStory();
      },
      finalize: function finalize() {// JavaScript to be fired on the home page, after the init JS
      }
    },
    // Story page
    'page_template_template_story': {
      init: function init() {
        templateStory(); // lib/modules/_section-story.js
      },
      finalize: function finalize() {// JavaScript to be fired on the story page, after the init JS
      }
    },
    // Story page (blog)
    'blog': {
      init: function init() {
        templateStory(); // lib/modules/_section-story.js
      },
      finalize: function finalize() {// JavaScript to be fired on the story page, after the init JS
      }
    },
    // News page (archive)
    'post_type_archive': {
      init: function init() {
        templateStory(); // lib/modules/_section-story.js

        showPlainTitlebar();
      },
      finalize: function finalize() {// JavaScript to be fired on the story page, after the init JS
      }
    },
    // Program Detail Page
    'page_template_template_program_details': {
      init: function init() {
        setupVideo(); // ./lib/modules/_section-program-detail.js
      },
      finalize: function finalize() {// JavaScript to be fired on the home page, after the init JS
      }
    },
    // Giving Monthly Page
    'page_template_template_giving_monthly': {
      init: function init() {
        setupPurpleBanner(); // ./lib/modules/_section-giving-monthly.js
      },
      finalize: function finalize() {// JavaScript to be fired on the home page, after the init JS
      }
    },
    // General Content Page
    'page_template_template_general_content': {
      init: function init() {
        setupGeneralContentHeader(); // ./lib/modules/_section-general-content.js
      },
      finalize: function finalize() {// JavaScript to be fired on the home page, after the init JS
      }
    },
    // General Content Page w/ Scripts
    'page_template_template_general_with_script': {
      init: function init() {
        setupGeneralContentHeader(); // ./lib/modules/_section-general-content.js
      },
      finalize: function finalize() {// JavaScript to be fired on the home page, after the init JS
      }
    },
    // General Content Page / Default
    'page_template_default': {
      init: function init() {
        setupGeneralContentHeader(); // ./lib/modules/_section-general-content.js
      },
      finalize: function finalize() {// JavaScript to be fired on the home page, after the init JS
      }
    },
    // Search Results Page
    'search': {
      init: function init() {
        setupGeneralContentHeader(); // ./lib/modules/_section-general-content.js

        templateStory();
      },
      finalize: function finalize() {// JavaScript to be fired on the home page, after the init JS
      }
    },
    // 404 Page
    'error404': {
      init: function init() {
        setupGeneralContentHeader(); // ./lib/modules/_section-general-content.js
      },
      finalize: function finalize() {// JavaScript to be fired on the home page, after the init JS
      }
    },
    // About Page
    'page_template_template_about': {
      init: function init() {
        activateHistorySlider(); // lib/modules/_section-about.js
      },
      finalize: function finalize() {// JavaScript to be fired after the init JS
      }
    },
    // Contact Page
    'page_template_template_contact': {
      init: function init() {
        setupFaqSection(); // lib/modules/_section-contact.js

        var stickyElements = document.querySelectorAll('.has-sticky .sticky');
        Stickyfill.add(stickyElements); // Polyfill for IE Sticky elements
      },
      finalize: function finalize() {// JavaScript to be fired after the init JS
      }
    },
    // Join Mailing List
    'page_template_template_join_mailing_list': {
      init: function init() {
        // setupFaqSection(); // lib/modules/_section-contact.js
        var stickyElements = document.querySelectorAll('.has-sticky .sticky');

        function showPlainTitlebar() {
          var titleBar = document.querySelector('.general-content .content-title');

          if (titleBar) {
            titleBar.classList.remove('hidden');
            titleBar.addEventListener('transitionend', function () {
              var content = document.querySelector('.container.container-content');
              content.classList.remove('hidden');
            });
          }
        }

        setTimeout(function () {
          showPlainTitlebar();
        }, 50);
        Stickyfill.add(stickyElements); // Polyfill for IE Sticky elements
      },
      finalize: function finalize() {// JavaScript to be fired on the story page, after the init JS
      }
    },
    // Events
    'page_template_template_events_php': {
      init: function init() {
        function showPlainTitlebar() {
          var titleBar = document.querySelector('.general-content .content-title');

          if (titleBar) {
            titleBar.classList.remove('hidden');
            titleBar.addEventListener('transitionend', function () {
              var content = document.querySelector('.container.container-content');
              content.classList.remove('hidden');
            });
          }
        }

        showPlainTitlebar();
      },
      finalize: function finalize() {// JavaScript to be fired on the story page, after the init JS
      }
    },
    // Groups Create
    'group_create': {
      init: function init() {
        var groupForm = document.getElementById('create-group-form');
        var deleteContainer = document.getElementById('bp-avatar-delete');
        var next = document.getElementById('group-creation-next');

        if (groupForm.baseURI.includes('/groups/create/step/group-avatar/') === true) {
          deleteContainer === null ? next.disabled = true : null;
          $(document).on('click', '.avatar-crop-submit', function () {
            return jQuery('.bp-avatar-status').length === 1 ? next.disabled = false : null;
          });
          $(document).on('click', '#bp-delete-avatar', function () {
            return next.disabled = true;
          });
        }
      },
      finalize: function finalize() {}
    },
    // Groups Listing
    'directory': {
      init: function init() {
        setTimeout(function () {
          if (document.getElementById('groups-list') !== null) {
            $('.group-button').each(function () {
              $(this).data('bp-btn-action') === 'accept_invite' ? $(this).remove() : null;
            });
          }
        }, 750);
      }
    }
  }; // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event

  var UTIL = {
    fire: function fire(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function loadEvents() {
      // Fire common init JS
      UTIL.fire('common'); // Fire page-specific init JS, and then finalize JS

      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      }); // Fire common finalize JS

      UTIL.fire('common', 'finalize');
    }
  }; // Load Events

  $(document).ready(UTIL.loadEvents);
})(window.jQuery, window.Clique); // Fully reference jQuery after this point.
// Misc JS
// Custom dropdown from w3schools
// https://www.w3schools.com/howto/howto_custom_select.asp


var x, i, j, selElmnt, a, b, c;
/*look for any elements with the class "custom-dropdown":*/

x = document.getElementsByClassName('custom-dropdown');

for (i = 0; i < x.length; i++) {
  selElmnt = x[i].getElementsByTagName('select')[0];
  /*for each element, create a new DIV that will act as the selected item:*/

  a = document.createElement('DIV');
  a.setAttribute('class', 'select-selected');
  a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
  x[i].appendChild(a);
  /*for each element, create a new DIV that will contain the option list:*/

  b = document.createElement('DIV');
  b.setAttribute('class', 'select-items select-hide');

  for (j = 1; j < selElmnt.length; j++) {
    /*for each option in the original select element,
    create a new DIV that will act as an option item:*/
    c = document.createElement('DIV');
    c.innerHTML = selElmnt.options[j].innerHTML;
    c.addEventListener('click', function (e) {
      /*when an item is clicked, update the original select box,
      and the selected item:*/
      var y, i, k, s, h;
      s = this.parentNode.parentNode.getElementsByTagName('select')[0];
      h = this.parentNode.previousSibling;

      for (i = 0; i < s.length; i++) {
        if (s.options[i].innerHTML === this.innerHTML) {
          s.selectedIndex = i;
          h.innerHTML = this.innerHTML;
          y = this.parentNode.getElementsByClassName('same-as-selected');

          for (k = 0; k < y.length; k++) {
            y[k].removeAttribute('class');
          }

          this.setAttribute('class', 'same-as-selected');
          break;
        }
      }

      h.click();
    });
    b.appendChild(c);
  }

  x[i].appendChild(b);
  a.addEventListener('click', function (e) {
    /*when the select box is clicked, close any other select boxes,
    and open/close the current select box:*/
    e.stopPropagation();
    closeAllSelect(this);
    this.nextSibling.classList.toggle('select-hide');
    this.classList.toggle('select-arrow-active');
  });
}

function closeAllSelect(elmnt) {
  /*a function that will close all select boxes in the document,
  except the current select box:*/
  var x,
      y,
      i,
      arrNo = [];
  x = document.getElementsByClassName('select-items');
  y = document.getElementsByClassName('select-selected');

  for (i = 0; i < y.length; i++) {
    if (elmnt == y[i]) {
      arrNo.push(i);
    } else {
      y[i].classList.remove('select-arrow-active');
    }
  }

  for (i = 0; i < x.length; i++) {
    if (arrNo.indexOf(i)) {
      x[i].classList.add('select-hide');
    }
  }
}
/*if the user clicks anywhere outside the select box,
then close all select boxes:*/


document.addEventListener('click', closeAllSelect); // Helper to get width or height of the window.
// 'd' specifies which dimension (width or height) to get.

function getSize(dimension) {
  var w = 0;
  var h = 0;

  if (window.outerHeight) {
    w = window.outerWidth;
    h = window.outerHeight;
  } else {
    w = document.body.clientWidth;
    h = document.body.clientHeight;
  }

  if (dimension === 'width' || dimension === 'w') {
    return w;
  } else if (dimension === 'height' || dimension === 'h') {
    return h;
  } else {
    console.error("".concat(d, " is not valid for this function.  Please use 'width', 'w', 'height', or 'h'"));
  }
} // video controls


function setupVideoControls() {
  try {
    var videos = document.querySelectorAll('.video-container');
    videos.forEach(function (videoContainer) {
      videoContainer.addEventListener('click', function () {
        this.classList.add('playing');
        var video = videoContainer.querySelector('video');
        video.setAttribute('controls', 'true');
        video.play();
      });
    });
  } catch (error) {
    console.error(error);
  }
}

function setupFormButtons() {
  // Setup a fake button with label tab that can mirror the style of the
  // other buttons w/ the green line.
  var submitButtons = document.querySelectorAll('input[type=submit].gform_button');
  submitButtons.forEach(function (submitButton) {
    var fakeButton = document.createElement('button');
    fakeButton.classList.add('hover-cta', 'btn');
    fakeButton.setAttribute('for', submitButton.getAttribute('id'));
    fakeButton.style.cursor = 'pointer';
    fakeButton.innerHTML = "\n\t\t\t<span class=\"linkText\">Submit</span>\n\t\t\t<span class=\"greenLine\" aria-hidden=\"true\"></span>\n\t\t";
    submitButton.parentNode.insertBefore(fakeButton, submitButton);
    submitButton.style.width = '0px';
    submitButton.style.height = '0px';
    submitButton.style.padding = '0px';
  });
}

var dropdowns = document.querySelectorAll('.ginput_container_select');
dropdowns.forEach(function (dropdown) {
  var select = dropdown.querySelector('select');
  select.addEventListener('change', function () {
    this.parentNode.classList.remove('clicked');
    this.stopPropagation();
  });
  select.addEventListener('blur', function () {
    this.parentNode.classList.remove('clicked');
    this.stopPropagation();
  });
  select.addEventListener('focus', function () {
    this.parentNode.classList.add('clicked');
  });
});

function accessibleNav() {
  var navItems = document.querySelectorAll('nav a');

  if (navItems) {
    navItems.forEach(function (navItem) {
      navItem.addEventListener('focus', function () {
        var thisParent = navItem.closest('.menu-item-has-children');

        if (thisParent) {
          thisParent.classList.add('active');
        }
      });
      navItem.addEventListener('blur', function () {
        var thisParent = navItem.closest('.menu-item-has-children');

        if (thisParent) {
          thisParent.classList.remove('active');
        }
      });
    });
  }
}

function showPlainTitlebar() {
  var titleBar = document.querySelector('.general-content .content-title');

  if (titleBar) {
    titleBar.classList.remove('hidden');
    titleBar.addEventListener('transitionend', function () {
      var content = document.querySelector('.container.container-content');
      content.classList.remove('hidden');
    });
  }
}