"use strict";

function getOffset(element) {
  // returns the offset of an element
  var top = 0,
      left = 0;

  do {
    top += element.offsetTop || 0;
    left += element.offsetLeft || 0;
    element = element.offsetParent;
  } while (element);

  return {
    top: top,
    left: left
  };
}

;

function setupFormButtons() {
  var formButtons = document.querySelectorAll('.gform_footer input[type=submit]');

  try {
    formButtons.forEach(function (button) {
      var thisParent = button.parentElement;
      var greenLine = document.createElement('div');
      greenLine.classList.add('greenLine');
      thisParent.appendChild(greenLine);
    });
  } catch (error) {
    console.log(error);
  }
}