"use strict";

function setupGeneralContentHeader() {
  setTimeout(function () {
    showPlainTitlebar();
  }, 50);

  function showPlainTitlebar() {
    var titleBar = document.querySelector('.general-content .content-title');

    if (titleBar) {
      titleBar.classList.remove('hidden');
      titleBar.addEventListener('transitionend', function () {
        var content = document.querySelector('.container.container-content');
        content.classList.remove('hidden');
      });
    }

    ;
  }

  ;
}

;