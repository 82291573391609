"use strict";

function kickoffScrollEvents() {
  /**
   * Scrolling and animations
   */
  var animationTicking = false;
  var lastKnownPos = 0;
  var windowHeight = window.innerHeight;
  var sectionHeaders = document.querySelectorAll('.section-header-container');
  var sectionsToWatch = document.querySelectorAll('.section');
  var fadeInNodes = document.querySelectorAll('.fadein-self.hidden');
  document.addEventListener('scroll', function () {
    lastKnownPos = window.pageYOffset;
    requestAnimationTick(lastKnownPos);
  });
  requestAnimationTick(lastKnownPos);

  function requestAnimationTick() {
    if (!animationTicking) {
      window.requestAnimationFrame(updatePage);
    }

    animationTicking = true;
  }

  function updatePage() {
    var currentPos = lastKnownPos;
    animationTicking = false;
    if (currentPos < 0) currentPos = 0; // prevent negative values
    // Functions to run on scroll

    watchSections(sectionsToWatch, 100);
    watchFadeins(fadeInNodes, 100);
  }

  function watchSections(sections, offset) {
    sections.forEach(function (section) {
      var thisPos = section.getBoundingClientRect().top;

      if (thisPos < windowHeight - offset) {
        showSection(section);
      }
    });
  }

  function showSection(section) {
    // Function to show the section header when scrolled to.
    var headers = section.querySelectorAll('.section-header-container');

    if (headers) {
      var headerTimer = 250; // Show headers one at a time

      headers.forEach(function (header) {
        setTimeout(function () {
          header.classList.remove('clipped');
        }, headerTimer);
        headerTimer += 500; // stagger each section header by 500ms
      }); // Once headers are done, we'll show the content

      setTimeout(function () {
        showContent(section);
      }, headerTimer);
    } else {
      setTimeout(function () {
        showContent(section);
      }, 250);
    }

    function showContent(section) {
      var content = section.querySelector('.container-content');
      if (content) content.classList.remove('hidden');
      animateModule(section);
    }
  }

  function watchFadeins(fadeins, offset) {
    fadeins.forEach(function (fadein) {
      if (fadein.querySelector('.fadein-image')) {}

      if (fadein.classList.contains('hidden')) {
        var fadeinPos = fadein.getBoundingClientRect().top;

        if (fadeinPos < windowHeight - offset) {
          fadeinImage(fadein);
          fadein.classList.remove('hidden');
        }
      }
    }); // helper function for images

    function fadeinImage(fadein) {
      var imageArea = fadein.querySelector('.fadein-image');
      if (!imageArea) return false;
      var timer = 500;
      setTimeout(function () {
        imageArea.classList.remove('hidden');
      }, 0);
      setTimeout(function () {
        imageArea.classList.remove('purple');
      }, timer);
    }
  }
}